import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SEO, Container, Banner, Modal, ProjectPopup, FadeIn } from 'components';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import { H4, P } from '../../styles/mixins';

export const query = graphql`
  query HeritagePageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    bannerImage: sanityCategory(slug: { current: { eq: "classic" } }) {
      categoryImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
    projects: allSanityProject(
      filter: { projectCategory: { slug: { current: { eq: "classic" } } } }
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          _rawBody
          title
          projectImages {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
            caption
          }
          projectImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
            caption
          }
          _id
          slug {
            current
          }
        }
      }
    }
  }
`;

const HeritagePage = (props) => {
  const { data } = props;
  const { windowWidth } = useWindowSize();
  const { site } = data || {};
  const { bannerImage } = data;
  const { edges: projects } = data.projects;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  const banner = {
    caption: 'Classic Kitchens',
    text: 'To view a selection of our recent classic kitchen designs below, simply click a tile to open the gallery..',
    sanityImage: bannerImage.categoryImage
  };

  return (
    <main>
      <SEO
        title="Classic Kitchens"
        description="Whether it’s a contemporary painted shaker style or something a little more traditional, we will always enjoy the challenge of providing our clients with the perfect design to suit their home."
        keywords={site.keywords}
      />
      <Banner banner={banner} />
      <Description>
        Whether it’s a contemporary painted shaker style or something a little more traditional, we
        will always enjoy the challenge of providing our clients with the perfect design to suit
        their home. There will never be any restrictions in terms of the dimensions or finish of our
        cabinetry. The door style, colours, and accessories are all to be discussed in our design
        studio.
      </Description>
      <Container>
        <FadeIn>
          <Grid>
            {projects.map(({ node }, i) => (
              <Modal
                key={i}
                trigger={
                  <GridItem>
                    <Content>
                      <GatsbyImage
                        image={node.projectImage.asset.gatsbyImageData}
                        alt={node.title}
                      />
                      <Title>
                        <H4>{node.title}</H4>
                      </Title>
                    </Content>
                  </GridItem>
                }
                modalContent={<ProjectPopup node={node} />}
              />
            ))}
          </Grid>
        </FadeIn>
      </Container>
    </main>
  );
};

const Description = styled(P)`
  width: 100%;
  max-width: 750px;
  padding: 1rem;
  margin: 1rem auto;
  text-align: center;
`;

const Title = styled.div`
  width: 100%;
  height: auto;
  padding: 1rem;
  background: none;
  backdrop-filter: blur(3px);

  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.75;
  transition: 0.5s;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  opacity: 0.75;
  transition: 0.5s;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const GridItem = styled.button`
  border: none;
  outline: none;
  background: none;
  height: 100%;
  width: 100%;
  cursor: pointer;

  &:hover ${Title} {
    opacity: 1;
  }
  &:hover ${Content} {
    opacity: 1;
  }
  .gatsby-image-wrapper {
    aspect-ratio: 1/1;
    height: auto;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin: 0 auto;
  margin-bottom: 5rem;
  padding: 1rem;
  align-items: start;

  @media (min-width: 768px) {
    padding: 0;
    margin: 3rem auto;
    margin-bottom: 8rem;
    max-width: 720px;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default HeritagePage;
